<template>
  <div
    v-if="dataRetrieved"
    id="ads"
  >
    <b-row class="mb-5">
      <b-col
        md="9"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t("adsDisplay.ads") }}</h1>
        <h5 class="text-primary">
          {{ $t("adsDisplay.ads-description") }}
        </h5>
      </b-col>
      <b-col
        md="3"
        class="text-right"
        align-self="end"
      >
        <b-button
          variant="primary"
          @click="AddAnuncios"
        >
          <span class="text-nowrap">{{ $t("advertising.AdAdd") }}</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-content-end align-items-center">
        <router-link to="/displayAdvertising/campaigns">
          <b-button variant="danger">
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>
    <b-table
      v-if="ads && ads.length >= 1"
      id="table"
      class="mt-1"
      :striped="true"
      sticky-header="650px"
      :no-border-collapse="true"
      responsive="sm"
      hover
      fixed
      :items="tableAds"
      show-empty
      :fields="columns"
      @row-clicked="adRowClicked"
    >
      <template #cell(name)="data">
        <div
          id="acortarPadre"
          v-b-tooltip.hover.bottom="data.item.name"
          class="text-nowrap "
        >
          <span>{{ data.item.name }}</span>
        </div>
      </template>
      <template #cell(location)="data">
        <div class="text-nowrap ">
          <span>{{ locationOptions.find(option => option.value === data.item.location).text }}</span>
        </div>
      </template>
      <template #cell(isActive)="data">
        <div class="text-nowrap ">
          <h4>
            <b-badge
              pill
              :variant="`light-${data.item.isActive ? 'success' : 'danger'}`"
              class="text-capitalize"
            >
              <feather-icon
                :icon="data.item.isActive ? 'CheckIcon' : 'XIcon'"
                size="20"
                class="mr-50"
              />
              {{
                data.item.isActive
                  ? $t("dataGeneric.active")
                  : $t("dataGeneric.inactive")
              }}
            </b-badge>
          </h4>
        </div>
      </template>
      <template #cell(action)="data">
        <div class="text-nowrap d-flex justify-content-around">
          <feather-icon
            icon="Edit2Icon"
            class="text-success cursor-pointer"
            size="18"
            @click.stop.prevent="routeToEditView(data.item.id)"
          />
          <feather-icon
            icon="DeleteIcon"
            class="text-danger cursor-pointer"
            size="18"
            @click.stop.prevent="deleteFolder(data.item.id)"
          />
        </div>
      </template>
    </b-table>
    <b-row
      v-else
      class="mt-2"
    >
      <b-col>
        <b-jumbotron
          bg-variant="transparent"
          border-variant="primary"
          :header="$t('advertising.NotAdds')"
        >
          <p class="text-primary">
            {{ $t("advertising.NotAddOnCampaign") }}
          </p>
        </b-jumbotron>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BButton,
  BRow,
  BCol,
  BJumbotron,
  BTable,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError } from '@/store/functions'

import { getUserData } from '@/auth/utils'

const noCover = require('@/assets/images/backend/nocover.jpeg')
const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BJumbotron,
    BTable,
    BBadge,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      dataRetrieved: false,
      ads: [],
      tableAds: [],
      userData: getUserData(),
      locationOptions: [
        { value: 'TOP', text: this.$t('top') },
        { value: 'MID', text: this.$t('middle') },
        { value: 'BOT', text: this.$t('bottom') },
      ],
      columns: [
        { key: 'name', label: this.$t('firstName'), sortable: true },
        { key: 'location', label: this.$t('location'), sortable: true },
        { key: 'isActive', label: this.$t('editContent.status'), sortable: true },
        { key: 'action', label: this.$t('message.tableHeader.action') },
      ],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      const { campaign } = this.$route.params
      axios
        .post('', {
          query: `
           query{
            allDisplayAds(campaign: "${campaign}"
            ){
              edges {
                node {
                  id               
                  name
                  description                 
                  campaign{
                    name
                  }
                  location
                  adUnitId
                  position
                  isActive                  
                }
              }
            }              
          }          
        `,
        })
        .then(result => {
          messageError(result, this)

          const ads = result.data.data.allDisplayAds.edges
          this.ads = []
          ads.forEach(element => {
            this.ads.push(element.node)
          })

          this.tableAds = [...this.ads]
          this.dataRetrieved = true
        })
        .catch(() => { })
    },
    AddAnuncios() {
      const { campaign } = this.$route.params

      this.$router.push({ name: 'display-ads-create', params: { campaign } })
    },
    deleteAd(id) {
      this.ads = this.ads.filter(item => item.node.id !== id)
    },
    deleteFolder(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
          mutation{
            deleteDisplayAd(id:"${id}") {
              found
              deletedId
            }
          }`,
            })
            .then(res => {
              messageError(res, this)

              this.tableAds = this.tableAds.filter(item => item.id !== id)
              this.ads = this.ads.filter(item => item.id !== id)
            })
        }
      })
    },
    routeToEditView(adId) {
      this.$router.push({ name: 'display-ads-edit', params: { id: adId } })
    },
    adRowClicked(ad) {
      this.routeToEditView(ad.id)
    },
    fetchData(folder) {
      this.$refs.categoryCard.fetchData(null, folder)
    },
    buildImageUrl(folder) {
      if (folder.node.content && folder.node.content.imageUrl) {
        return folder.node.content.imageUrl == null
          || folder.node.content.imageUrl.length === 0
          ? noCover
          : folder.node.content.imageUrl
      }
      return noCover
    },
    errorImg(e) {
      e.target.src = fileError
    },
  },
}
</script>
<style scoped>
#ads .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#ads .cardContent img {
  height: 11vw;
  object-fit: cover;
  /*width: 100%;
    height:  100%;
    object-fit: cover;*/
}

#ads .card-body {
  padding: 1rem;
}

#ads .card-body h4 {
  font-size: 1rem !important;
}

#ads .card-header {
  padding: 1rem;
}

#ads .card-header h4 {
  font-size: 1rem !important;
}

#ads .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

/*.folder {
  min-height: 150px;
  height: 300px;
  cursor: all-scroll;
}*/
#ads .dropContainer {
  border: 3px dashed;
}

/*.card-width {
  width: 200px;
}*/
#ads .list-group-item {
  transition: all 1s;
}

#ads #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#ads #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}
</style>
